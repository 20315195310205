import { loadScript } from '@paypal/paypal-js'

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()
  const { paypalClientId } = config.public

  const paypal = await loadScript({
    clientId: paypalClientId,
    currency: 'USD',
    components: ['buttons', 'card-fields']
  })

  nuxtApp.provide('paypal', paypal)
})
