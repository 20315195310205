import { useLocalStorage } from '@vueuse/core'

export const useAppStore = defineStore('appStore', {
  state: () => ({
    // User
    inited: false,
    session: null,
    user: null,

    loading_resources: false,

    // Team
    teams: [],
    selected_team_id: useLocalStorage('apc-selected_team_id', null),

    // Model
    models: [],
    selected_model_id: useLocalStorage('apc-selected_model_id', null),

    // Canvas
    canvases: [],
    canvas: null,

    // Asset
    assets: []
  }),
  actions: {
    reset() {
      this.user = null

      this.loading_resources = false

      this.teams = []
      this.selected_team_id = null

      this.models = []
      this.selected_model_id = null

      this.canvases = []
      this.canvas = null

      this.assets = []
    },

    setLoadingResources(val) {
      this.loading_resources = val
    },

    // Team
    async getTeams() {
      const { $fetch } = useNuxtApp()
      const { data } = await $fetch('/api/teams')
      this.teams = data
    },
    setSelectedTeamId(id) {
      this.selected_team_id = id
    },
    async checkTeamUsername(username) {
      const { $fetch } = useNuxtApp()
      const { data } = await $fetch('/api/teams-username', {
        method: 'POST',
        body: {
          username
        }
      })
      return data
    },
    async createTeam(input) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/teams', {
        method: 'POST',
        body: input
      })
      if (error) {
        throw new Error(error)
      }

      this.teams.push(data)

      return data
    },
    async updateTeam(input) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/teams', {
        method: 'PATCH',
        body: input
      })
      if (error) {
        throw new Error(error)
      }

      this.upsertLocalTeam(data)

      return data
    },
    upsertLocalTeam(team) {
      const index = this.teams.findIndex((i) => i.id === team?.id)
      if (index !== -1) {
        this.teams[index] = team
      } else {
        this.teams.push(team)
      }
    },
    decreaseLocalTeamBalance({ team_id, amount }) {
      const index = this.teams.findIndex((i) => i.id === team_id)
      if (index !== -1) {
        this.teams[index].balance -= amount
      }
    },
    increaseLocalTeamBalance({ team_id, last_balance, amount }) {
      const index = this.teams.findIndex((i) => i.id === team_id)
      if (index !== -1) {
        this.teams[index].balance += amount

        if (last_balance) {
          this.teams[index].last_balance = last_balance
        }
      }
    },

    // Model
    async getModels(team_id = null) {
      this.models = []

      const { $fetch } = useNuxtApp()
      const { data } = await $fetch('/api/models', {
        query: {
          team_id
        }
      })
      this.models = data
    },
    async createModel(input) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/models', {
        method: 'POST',
        body: input
      })
      if (error) {
        throw new Error(error)
      }

      this.upsertLocalModel(data)

      return data
    },
    async updateModel(input) {
      this.upsertLocalModel(input)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/models', {
        method: 'PATCH',
        body: input
      })
      if (error) {
        throw new Error(error)
      }

      return data
    },
    async deleteModel(id = null) {
      this.deleteLocalModel(id)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/models', {
        method: 'DELETE',
        body: { id }
      })
      if (error) {
        throw new Error(error)
      }

      return data
    },
    upsertLocalModel(model) {
      const index = this.models.findIndex((i) => i.id === model?.id)
      if (index !== -1) {
        this.models[index] = { ...this.models[index], ...model }
      } else {
        this.models.push(model)
      }
    },
    deleteLocalModel(id) {
      const index = this.models.findIndex((i) => i.id === id)
      if (index !== -1) {
        this.models.splice(index, 1)
      }
    },
    setSelectedModelId(id) {
      this.selected_model_id = id
    },

    // Favorite models
    async createFavoriteModel(team_id, model) {
      model.is_favorite = true
      this.upsertLocalModel(model)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/favorite-models', {
        method: 'POST',
        body: {
          team_id,
          model
        }
      })
      if (error) {
        throw new Error(error)
      }

      return data
    },
    async deleteFavoriteModel(team_id, model) {
      this.deleteLocalModel(model.id)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/favorite-models', {
        method: 'DELETE',
        body: { team_id, model_id: model.id }
      })
      if (error) {
        throw new Error(error)
      }

      return data
    },

    // Canvas
    async getCanvas(id = null) {
      const { $fetch } = useNuxtApp()
      const { data } = await $fetch('/api/canvas', {
        method: 'GET',
        query: {
          id
        }
      })

      this.canvas = data

      return data
    },
    async getCanvases(team_id = null) {
      this.canvases = []

      const { $fetch } = useNuxtApp()
      const { data } = await $fetch('/api/canvases', {
        query: {
          team_id
        }
      })
      this.canvases = data
    },
    async createCanvas(team_id = null) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/canvases', {
        method: 'POST',
        body: { team_id }
      })
      if (error) {
        throw new Error(error)
      }

      this.upsertLocalCanvas(data)

      return data
    },
    async updateCanvas(input) {
      this.upsertLocalCanvas(input)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/canvases', {
        method: 'PATCH',
        body: input
      })
      if (error) {
        throw new Error(error)
      }

      return data
    },
    async deleteCanvas(id = null) {
      this.deleteLocalCanvas(id)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/canvases', {
        method: 'DELETE',
        body: { id }
      })
      if (error) {
        throw new Error(error)
      }

      return data
    },
    upsertLocalCanvas(canvas) {
      const index = this.canvases.findIndex((i) => i.id === canvas?.id)
      if (index !== -1) {
        this.canvases[index] = canvas
      } else {
        this.canvases.push(canvas)
      }
    },
    deleteLocalCanvas(id) {
      const index = this.canvases.findIndex((i) => i.id === id)
      if (index !== -1) {
        this.canvases.splice(index, 1)
      }
    },
    setCanvas(canvas) {
      this.canvas = { ...this.canvas, ...canvas }
    },
    addCanvasShapes(shapes) {
      if (this.canvas) {
        this.canvas.data.shapes = [...this.canvas.data.shapes, ...shapes]
      }
    },
    deleteCanvasShapes(shapes) {
      if (this.canvas) {
        this.canvas.data.shapes = this.canvas.data.shapes.filter(
          (shape) => !shapes.includes(shape.id)
        )
      }
    },

    // Asset
    async getAssets(canvas_id = null) {
      const { $fetch } = useNuxtApp()
      const { data } = await $fetch('/api/assets', {
        query: {
          canvas_id
        }
      })
      this.assets = data
    },
    async createAsset(input) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/assets', {
        method: 'POST',
        body: input
      })
      if (error) {
        throw new Error(error)
      }

      this.upsertLocalAsset(data)

      return data
    },
    async createUpscaledAsset(input) {
      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/upscaled-assets', {
        method: 'POST',
        body: input
      })
      if (error) {
        throw new Error(error)
      }

      this.upsertLocalAsset(data)

      return data
    },
    async deleteAssets(ids = []) {
      if (ids.length <= 0) {
        return
      }

      this.deleteLocalAssets(ids)

      const { $fetch } = useNuxtApp()
      const { data, error } = await $fetch('/api/assets', {
        method: 'DELETE',
        body: { ids }
      })
      if (error) {
        throw new Error(error)
      }

      return data
    },
    upsertLocalAsset(asset) {
      const index = this.assets.findIndex((i) => i.id === asset?.id)
      if (index !== -1) {
        this.assets[index] = asset
      } else {
        this.assets.push(asset)
      }
    },
    deleteLocalAssets(ids) {
      this.assets = this.assets.filter((asset) => !ids.includes(asset.id))
    }
  },
  getters: {
    // Team
    personal_team_id: (state) =>
      state.user?.user_metadata?.personal_team_id || null,
    personal_team: (state) =>
      state.teams.find(
        (team) => team.id === state.user?.user_metadata?.personal_team_id
      ) || null,
    selected_team: (state) =>
      state.teams.find((team) => team.id === state.selected_team_id) || null,
    selected_model: (state) =>
      state.models.find((model) => model.id === state.selected_model_id) ||
      null,
    has_roles:
      (state) =>
      (roles = []) => {
        const member = (state.selected_team?.team_members || []).find(
          (i) => i.user_team_id === state.personal_team_id
        )
        const member_roles = member?.roles || []

        // Return boolean if there's an overlap in member_roles and roles
        return roles.some((role) => member_roles.includes(role))
      },
    has_favorite: (state) => (model) => {
      const index = state.models.findIndex((i) => i.id === model.id)
      if (index === -1) {
        return false
      }

      return !!state.models[index]?.is_favorite
    }
  }
})
