<template lang="pug">
#app(v-if="inited")
  nuxt-page
</template>

<script>
import { mapState } from 'pinia'

export default {
  name: 'app',
  computed: mapState(useAppStore, ['inited'])
}
</script>

<style lang="stylus" scoped></style>
